import React, { useState, useEffect } from "react";
import { CONVERSE_API } from "../utilities/constants";
import { useResponseLength } from '../contexts/ResponseLengthContext'; // Import the context hook
import { useMessage } from "../contexts/MessageContext";
import BotResponse from "./BotResponse"; // Import the new BotResponse component

const StreamingMessage = ({ initialMessage, setProcessing, modelName, modelId, handleBotResponse }) => {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [isChatError, setIsChatError] = useState(false); // State to track if chat error occurred
  const [isDocumentError, setIsDocumentError] = useState(false); // State to track if document error occurred
  const { responseLength } = useResponseLength(); // Use the context
  const { messageList, addMessage } = useMessage();

  useEffect(() => {
    const fetchResponse = async (sendLastMessageOnly = false) => {
      setProcessing(true);

      const requestBody = {
        user_message: initialMessage,
        selected_model: modelId,
        response_length: responseLength,
        ...(sendLastMessageOnly ? { message_history: [messageList[messageList.length - 1]] } : { message_history: messageList }),
      };

      try {
        const response = await fetch(CONVERSE_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (response.ok) {
          const botMessage = data.messages.content[0].text;
          setResponse(botMessage);
          setIsLoading(false);
          // Call the function to save the bot's response in the message list
          handleBotResponse(botMessage, modelName, modelId, sendLastMessageOnly ? "This model does not support chat. It is optimized for single-turn Q&A." : "" );
        } else {
          // Check if the error is related to conversation history
          if (data.error && data.error.includes("doesn't support conversation history")) {
            console.error("Model does not support conversation history, retrying with last message only");
            setIsChatError(true); // Set error state for conversation history
            fetchResponse(true);
          } 
          // Check if the error is related to document chat support
          else if (data.error && data.error.includes("doesn't support documents")) {
            console.error("Model does not support document chat");

            // Add dummy bot message to message list, this ensure that messages alternate between bot and user
            const dummyMessage = "---";
            handleBotResponse(dummyMessage, modelName, modelId, "This model does not support document-based chat. Kindly choose a different model to query the uploaded document.");
            setIsLoading(false);
            setIsDocumentError(true); // Set error state for document support
          } else {
            console.error("Error fetching response:", data.error);
          }
        }
      } catch (error) {
        console.error("Error making request:", error);
      } finally {
        // setIsLoading(false); // Stop loading animation when the request completes
        setProcessing(false);
      }
    };

    fetchResponse();
  }, [initialMessage]);

  return (
    <BotResponse
      response={response}
      isLoading={isLoading}
      modelName={modelName}
      isChatError={isChatError}
      isDocumentError={isDocumentError}
    />
  );
};

export default StreamingMessage;
