/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hQAUkiFbX",
    "aws_user_pools_web_client_id": "7nccu1ndkfo4gfmohvhqs92ltv",
    "oauth": {
        "domain": "nj-ai-assistant.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"            
        ],
        "redirectSignIn": "https://ai-advanced.nj.gov/chat",
        "redirectSignOut": "https://ai-advanced.nj.gov/",
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "ssistant-20240910121630-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dd0v3ou47cxa1.cloudfront.net"
};


export default awsmobile;
